import React from 'react';
import {View, ImageBackground} from 'react-native';
import Text from '@/components/basic/text';
import theme from '@/style';
import {toPriceStr} from '@/components/utils';
import globalStore from '@/services/global.state';
import LazyImage from '@/components/basic/image';
import {useInnerStyle} from './recharge.hooks';
import {useScreenSize} from '../hooks/size.hooks';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';
export interface RechargeBalanceProps {
  balance?: number;
  payMethod?: string;
  tip?: string;
  onRefresh?: () => void;
  onGotoRecords?: () => void;
}

const RechargeBalance: React.FC<RechargeBalanceProps> = props => {
  const {i18n} = useTranslation();
  const {
    balance = 0,
    payMethod = '',
    onRefresh = () => {},
    onGotoRecords,
    tip = i18n.t('recharge-page.tip.payMethodTip'),
  } = props;
  const {screenWidth, designWidth} = useScreenSize();
  const {
    size: {},
    amountStyle,
    balanceStyles: styles,
  } = useInnerStyle();
  const refreshIconSize = (24 * screenWidth) / designWidth;

  return (
    <ImageBackground
      resizeMode={'cover'}
      source={require('@assets/imgs/card-background-image.webp')}
      style={[theme.margin.lrl, styles.container]}>
      <View style={[theme.flex.between, theme.flex.flex1]}>
        <View
          style={[
            theme.padding.leftl,
            theme.padding.topl,
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
          ]}>
          <View style={[theme.flex.flex1]}>
            <Text color={theme.fontColor.brown} style={[amountStyle.opacity]}>
              {i18n.t('label.balance')}
            </Text>
            <View
              style={[theme.flex.row, theme.flex.alignEnd, theme.margin.topxs]}>
              <Text
                fontFamily="fontInter"
                blod
                fontSize={20}
                style={[theme.font.brown]}>
                {toPriceStr(balance, {
                  thousands: true,
                  spacing: true,
                  currency: globalStore.currency,
                })}
              </Text>
              <NativeTouchableOpacity
                activeOpacity={0.8}
                onPress={onRefresh}
                style={[theme.margin.leftm]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/refresh.webp')}
                  width={refreshIconSize}
                  height={refreshIconSize}
                />
              </NativeTouchableOpacity>
            </View>
          </View>
          <NativeTouchableOpacity
            activeOpacity={0.8}
            style={[theme.flex.row, theme.flex.centerByCol]}
            onPress={onGotoRecords}>
            <View
              style={[
                theme.flex.alignEnd,
                theme.padding.l,
                {
                  backgroundColor: '#E5D776',
                  borderTopLeftRadius: 15,
                  borderBottomLeftRadius: 15,
                },
              ]}>
              <ImageBackground
                source={require('@components/assets/icons/button-backImg-primary.webp')}
                resizeMode={'stretch'}
                style={[theme.flex.center, theme.padding.l]}>
                <Text blod color={theme.fontColor.white}>
                  {i18n.t('label.recharge')}{' '}
                  {i18n.t('recharge-page.label.records')}
                </Text>
              </ImageBackground>
            </View>
          </NativeTouchableOpacity>
        </View>
        <View
          style={[
            theme.padding.lrl,
            theme.padding.tbs,
            {backgroundColor: 'rgba(0,0,0,0.1)'},
          ]}>
          <Text color={theme.fontColor.brown}>
            {i18n.t('recharge-page.currentMethod')}:
            <Text color={theme.fontColor.brown} style={[theme.margin.leftxxs]}>
              {payMethod}
            </Text>
          </Text>
          <View style={[theme.margin.topxxs]}>
            <Text color={theme.fontColor.brown} style={[amountStyle.opacity]}>
              {tip}
            </Text>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default RechargeBalance;
