import React from 'react';
import Text from '@basicComponents/text';
import {View, Image} from 'react-native';
import theme from '@style';
import {PayMethod} from './recharge.service';
import LazyImage from '@/components/basic/image';
import {useInnerStyle} from './recharge.hooks';
import Ok from '../svg/ok';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useTranslation} from 'react-i18next';

export interface RechargeChannelProps {
  payMethodList: PayMethod[];
  payMethodId?: number;
  onPayMethodChange: (id: number) => void;
}

const RechargeChannel: React.FC<RechargeChannelProps> = ({
  payMethodList,
  payMethodId,
  onPayMethodChange,
}) => {
  const {i18n} = useTranslation();
  const {payMethodStyles} = useInnerStyle();
  return (
    <View style={[theme.flex.col]}>
      <Text fontSize={theme.fontSize.m} main style={[theme.margin.l]}>
        {i18n.t('recharge-page.label.channel')}
      </Text>
      <View
        style={[
          theme.flex.col,
          theme.flex.wrap,
          theme.background.white,
          theme.borderRadius.m,
          theme.padding.lrl,
        ]}>
        {payMethodList.map((payMethod, index) => {
          return (
            <NativeTouchableOpacity
              key={index}
              onPress={() => onPayMethodChange(payMethod.id)}>
              <View
                key={index}
                style={[
                  payMethodStyles.item,
                  theme.flex.row,
                  theme.position.rel,
                  theme.flex.centerByCol,
                  {
                    borderBottomWidth: index < payMethodList.length - 1 ? 1 : 0,
                    borderBottomColor: theme.basicColor.border,
                  },
                ]}>
                <View style={[theme.flex.center, theme.margin.rightl]}>
                  <LazyImage
                    occupancy="#0000"
                    imageUrl={payMethod.payIcon}
                    width={theme.iconSize.xxl}
                    height={theme.iconSize.xxl}
                  />
                </View>
                <Text
                  fontSize={theme.fontSize.s}
                  main
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[payMethodStyles.itemText, theme.font.center]}>
                  {payMethod.payName}
                </Text>
                {payMethod.id === payMethodId ? (
                  <View
                    style={[
                      theme.position.abs,
                      payMethodStyles.itemSelectedIcon,
                      theme.flex.center,
                    ]}>
                    <Ok />
                  </View>
                ) : (
                  <View
                    style={[
                      theme.position.abs,
                      payMethodStyles.itemNoSelectedIcon,
                      theme.flex.center,
                    ]}></View>
                )}
              </View>
            </NativeTouchableOpacity>
          );
        })}
      </View>
      <Text fontSize={theme.fontSize.m} main style={[theme.margin.l]}>
        Recharge instructions
      </Text>
      <View
        style={[
          theme.flex.col,
          theme.flex.wrap,
          theme.background.white,
          theme.borderRadius.m,
          theme.padding.lrl,
        ]}>
        <Text
          fontSize={theme.fontSize.s}
          style={[theme.padding.tbxs, theme.flex.flex, theme.flex.centerByCol]}
          main>
          <Image
            style={[
              theme.margin.rights,
              {
                width: 15,
                height: 15,
              },
            ]}
            source={require('@assets/icons/wallet/baoshi.webp')}
          />
          If the transfer time is up, please fill out the deposit form again.
        </Text>
        <Text
          fontSize={theme.fontSize.s}
          style={[theme.padding.tbxs, theme.flex.flex, theme.flex.centerByCol]}
          main>
          <Image
            style={[
              theme.margin.rights,
              {
                width: 15,
                height: 15,
              },
            ]}
            source={require('@assets/icons/wallet/baoshi.webp')}
          />
          The transfer amount must match the order you created, otherwise the
          money cannot be credited successfully.
        </Text>
        <Text
          fontSize={theme.fontSize.s}
          style={[theme.padding.tbxs, theme.flex.flex, theme.flex.centerByCol]}
          main>
          <Image
            style={[
              theme.margin.rights,
              {
                width: 15,
                height: 15,
              },
            ]}
            source={require('@assets/icons/wallet/baoshi.webp')}
          />
          If you transfer the wrong amount, our company will not be responsible
          for the lost amount!
        </Text>
        <Text
          fontSize={theme.fontSize.s}
          style={[theme.padding.tbxs, theme.flex.flex, theme.flex.centerByCol]}
          main>
          <Image
            style={[
              theme.margin.rights,
              {
                width: 15,
                height: 15,
              },
            ]}
            source={require('@assets/icons/wallet/baoshi.webp')}
          />
          Note: do not cancel the deposit order after the money has been
          transferred.
        </Text>
      </View>
    </View>
  );
};

export default RechargeChannel;
